import React from 'react';
import css from './ListingMobileInformation.module.css'
import IconCollection from '../IconCollection/IconCollection';
import { classNames } from 'primereact/utils';
import Button from '../Button/Button';

const ListingMobileInformation = (props) => {
  const { className } = props;
  return (
    <div className={classNames(css.informationContainer, className)}>
      <h2 className={css.informationTitle}>Information</h2>

      <div className={css.hoursSection}>
        <div className={css.hoursRow}>
          <span className={css.dayRange}>Monday – Thursday</span>
          <span className={css.timeRange}>10:00 AM – 11:00 PM</span>
        </div>
        <div className={css.hoursRow}>
          <span className={css.dayRange}>Friday – Sunday</span>
          <span className={css.timeRange}>01:00 PM – 11:00 PM</span>
        </div>
      </div>

      <div className={css.locationSection}>
        <div className={css.addressContainer}>
          <span className={css.address}>34 Panderman St. Gading Kasri, Malang 65115 Indonesia</span>
          <span className={css.distance}>1.3 km</span>
        </div>
        <div className={css.iconContainer}>
          <Button className={css.iconButton}>
            <IconCollection name="go_location" />
          </Button>
        </div>
      </div>

      <div className={css.phoneSection}>
        <div className={css.phoneNumber}>
          <span>(0341) 958-234</span>
        </div>
        <div className={css.iconContainer}>
          <Button className={css.iconButton}>
            <IconCollection name="phone_icon" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ListingMobileInformation;