import React from 'react';
import css from './ListingTab.module.css'
import { classNames } from 'primereact/utils';

const ListingTab = (props) => {
    const { activeTab, handleTabClick } = props;
    return (
        <div className={css.tabsContainer}>
            <div className={css.tabs}>
                <div
                    className={classNames(css.tabItem, activeTab === 'About' ? css.active : '')}
                    onClick={() => handleTabClick('About')}
                >
                    About
                </div>
                <div
                    className={classNames(css.tabItem, activeTab === 'Services' ? css.active : '')}
                    onClick={() => handleTabClick('Services')}
                >
                    Services
                </div>
                <div
                    className={classNames(css.tabItem, activeTab === 'Staff' ? css.active : '')}
                    onClick={() => handleTabClick('Staff')}
                >
                    Staff
                </div>
                <div
                    className={classNames(css.tabItem, activeTab === 'Products' ? css.active : '')}
                    onClick={() => handleTabClick('Products')}
                >
                    Products
                </div>
            </div>
        </div>
    );
};

export default ListingTab;