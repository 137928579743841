import React from 'react';
import css from './StaffList.module.css';
import staffImage from '../../assets/images/4.png'
import IconCollection from '../IconCollection/IconCollection';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';

const StaffList = () => {
  const staff = [
    {
      name: 'Jefferson Harris',
      rating: 4.9,
      specialty: 'Specialist in Shave',
      isFavorite: false,
      isSelected: false,
      imageUrl: staffImage, // Add image path
    },
    {
      name: 'Robert Collier',
      rating: 3.5,
      specialty: 'Specialist in Haircut',
      isFavorite: true,
      isSelected: true,
      imageUrl: staffImage,
    },
    {
      name: 'Ricardo Trautman',
      rating: 4.0,
      specialty: 'Specialist in Waxing',
      isFavorite: false,
      isSelected: false,
      imageUrl: staffImage,
    },
    {
      name: 'Satriani Moonwalk',
      rating: 4.5,
      specialty: 'Specialist in Mustache Trim',
      isFavorite: true,
      isSelected: false,
      imageUrl: staffImage,
    },
    {
      name: 'Abraham Erickson',
      rating: 4.9,
      specialty: 'Specialist in Beard Trim',
      isFavorite: false,
      isSelected: true,
      imageUrl: staffImage,
    },
  ];

  return (
    <div className={css.staffList}>
      {staff.map((staffMember, index) => (
        <div key={index} className={css.staffItem}>
          <div className={css.imageWrapper}>
            <ResponsiveImage
              alt={staffMember.name}
              className={css.avatar}
              image={staffMember.imageUrl}
              variants={"custom-image"}
            />
            {staffMember.isFavorite && <span className={css.favoriteIcon}>
              <IconCollection name="love_icon_red" />
            </span>}
          </div>
          <div className={css.detailsWrapper}>
            <div className={css.details}>
              <h3 className={css.name}>{staffMember.name}</h3>
              <p className={css.rating}>
                <IconCollection name="star_icon" /> {staffMember.rating} · {staffMember.specialty}
              </p>
            </div>
            <div className={css.icons}>

              <span className={staffMember.isSelected ? css.selectedIcon : css.unselectedIcon}></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StaffList;
