import React, { useState } from 'react';
import css from './ReadMore.module.css';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

const ReadMore = ({ text = '', maxLength = 0, className }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className={classNames(css.text, className)}>
      {text ? (
        isReadMore ? text.slice(0, maxLength) + (text.length >= maxLength ? '...' : '') : text
      ) : (
        ''
      )}
      {text && text.length > maxLength ? (
        <span className={css.readMoreLink} onClick={toggleReadMore}>
          {isReadMore ? (
            <FormattedMessage id="ReadMore.readmore" />
          ) : (
            <FormattedMessage id="ReadMore.showless" />
          )}
        </span>
      ) : null}
    </p>
  );
};

export default ReadMore;
