import React from 'react';
import { SERVICE_LISTING_TYPE } from '../../util/types';
import { Button, ResponsiveImage } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './DealItemsMaybe.module.css'

const DealItemsMaybe = (props) => {
  const {
    dealItem,
    setIsBookingModalOpen,
    isBooked,
    onFetchDealItemMonthlyTimeSlots
  } = props;

  const { images = [], attributes } = dealItem || {}
  const { title, publicData } = attributes || {}
  const { listingType } = publicData || {};

  const isService = listingType === SERVICE_LISTING_TYPE;
  const firstImage = images && images?.length > 0 ? images[0] : null;

  return (
    <div>
      <div className={css.dealItem}>
        <ResponsiveImage
          image={firstImage}
          variants={['listing-card', 'listing-card-2x']}
          alt={'listing image'}
        />
        <h3>{title}</h3>
        {isService ?
          isBooked ?
            <Button type="button" disabled={true}>
              <FormattedMessage id="DealItemsMaybe.booked" />
            </Button>
            :
            <Button type="button"
              onClick={() => {
                setIsBookingModalOpen()
                onFetchDealItemMonthlyTimeSlots(dealItem)
              }}>
              <FormattedMessage id="DealItemsMaybe.bookService" />
            </Button>
          : null}
      </div>

    </div>
  );
}

export default DealItemsMaybe;
